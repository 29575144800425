import React from 'react'
import { useState, useEffect } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandText from "../Components/ExpandText";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Box, Button } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
// import Loader from "../Component/Loader";
// import SimilarIcon from "../Images/ICON.png";
// import ContractIcon from "../Images/ICON_Contract.png"
// import ContarctsViewPopup from "./ContarctsViewPopup";
import ContractViewImage from "./ContractViewImage";
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// import AppInsight from '../AppInsight';
// import SnackBar from '../Component/SnackBar';
import ContractIcon from '../Img/ICON_Contract.png';


const SearchQueryResult = (props) => {
  const [data, setData] = useState(props.data);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [strRow, setstrRow] = useState();

  const [showLoader, setShowLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showContractImage, setShowContractImage] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [agreementNumber, setAgreementNumber] = useState("");
  const [pageNumber, setPageNumber] = useState("");
  const params = useParams();
  const [showSnackbar,setShowSnackbar] = useState(false);




  
  ///////////////////////change

  const transformData=(data)=>{
    return data.map(item=>{
      const {["@search.score"]:removedKey,...rest}=item;
      return{
        ...rest
          };
    });
  };

  const transformedData=transformData(data);


  useEffect(() => {
    try{
    setShowLoader(props.loaderValue);
    setShowTable(props.tableValue);
    }

    catch(error)
    {
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'useEffect-error-searchResult=>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackbar(true);
    }
  }, [props.loaderValue, props.tableValue])


  useEffect(() => {
    try{
    setData(props.data);
    }
    catch(error)
    {
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'useEffect-error-searchResult=>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackbar(true);

    }
  }, [props.data]);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
     ///change//////////
     try{
    const csv = generateCsv(csvConfig)(transformedData);
    
    download(csvConfig)(csv);
    }
    catch(error){
    //   // console.log('error in handle---',error)
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'error-handle-export-data-searchResult=>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackbar(true);


    }
    ///////////change
  };
  const handleExportRows = (rows) => {
     //////change
     try{
    
     const rowData = rows.map((row) => row.original);
     const rowDataRemoveEmbeddingsCol= rowData.map(({["@search.score"]:removedKey,...rest})=>rest);
    //  console.log('rowData',rowDataRemoveEmbeddingsCol);
     const csv = generateCsv(csvConfig)(rowDataRemoveEmbeddingsCol);
     download(csvConfig)(csv);
     }

     catch(error)
     {
    //    AppInsight.trackException({error});
    //    AppInsight.trackEvent({name:'error-handleExportRows-searchResult=>frontend',properties:{errorMessage:error.message}});
    //    setShowSnackbar(true);
 
     }
     //////change
  };


  const openPopup = async (rowId) => {
    try{
    setstrRow(rowId);
    handleShow();
    }
    catch(error){
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'error-openPopup-searchResult=>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackbar(true);

    }
  }

//   const handleSimilarParagraphsPopup = (id, reqId, docId) => {
//     try{
//     setShowSimilarParagraphsPopup(true);
//     setRequestId(reqId);
//     setDocNumber(docId);
//     setRowIndex(id);
//     }

//     catch(error)
//     {
//       AppInsight.trackException({error});
//       AppInsight.trackEvent({name:'error-handleSimilarParagraphs-searchResult=>frontend',properties:{errorMessage:error.message}});
//       setShowSnackbar(true);

  
//     }
//   }

  const handleContractViewImage = (id, agreementNo, pageNo) => {
    try{
    setShowContractImage(true);
    setRowIndex(id);
    setAgreementNumber(agreementNo);
    setPageNumber(pageNo);
    }

    catch(error)
    {
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'error-handleContractViewImage-searchResult=>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackbar(true);


    }

  }

//   const closeSimilarParagraphsPopup = () => {
//     setShowSimilarParagraphsPopup(false);

//   }
const formatDate = (dateW) => {
  const date = new Date(dateW);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear());
  const hour = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  return `${month}-${day}-${year}`;
}

  const closeContractViewImage = () => {
    setShowContractImage(false);

  }

  let [columns, setColumns] = useState(
    () => [

      {
        accessorKey: 'Agreement_Number', //normal accessorKey
        header: 'Agreement Number',
        enableClickToCopy: true,
        minSize: 50,
        maxSize: 50,
        size: 50,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Agreement Number' },
      },

      {
        accessorKey: 'Contract_Name',
        header: 'Contract Name',
        minSize: 300,
        maxSize: 300,
        size: 300,
        Cell: ({ renderedCellValue, row }) => (
          <div >
        <ExpandText  text={row.original.Contract_Name} maxLength={65} />
 
          </div>
        ),
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Contract Name' },
      },
      {
        accessorKey: 'Client',
        header: 'Payer Name',
        // filterVariant: 'multi-select',
        //filterSelectOptions: clientList,
        minSize: 150,
        maxSize: 150,
        size: 150,
       
        muiTableHeadCellProps: { sx: { color: "#002677" }}, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Client' },
      },
      {
        accessorKey: 'Text',
        header: 'Text',
        Cell: ({ renderedCellValue, row }) => (
          <div >
               <ExpandText text={row.original.Text} maxLength={70} />
 
          </div>
        ),
        minSize:300,
        maxSize:300,
        size:300,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Text' },
      },

      {
        accessorKey: 'Effective_Date',
        header: 'Effective Date',
        filterVariant: 'date-range',
        filterFn: (row,columnId,filterValue)=>{
          const rowValue= new Date(row.getValue(columnId)); 
          const [start,end] = filterValue;
          const startDate = start?new Date(start):null;
          const endDate = end?new Date(end):null; 
          if(!start&&!end)
          return true; 
          if(startDate)
         startDate.setHours(0,0,0,0); 
          if(endDate)
          endDate.setHours(23,59,59,999);
          if(startDate&&!endDate)
          return rowValue>=startDate;
          if(!startDate&&endDate)
          return rowValue<=endDate;
          if(startDate&& endDate)
          return rowValue>=startDate && rowValue<=endDate;
        },
        Cell:({cell})=>formatDate(cell.getValue()),
        minSize: 50,
        maxSize: 50,
        size: 50,
        muiTableHeadCellProps: { sx: { color: "#002677" }}, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Effective Date' },
      },

      {
        accessorKey: 'Product',
        header: 'Product',
        minSize: 150,
        maxSize: 150,
        size: 150,
        muiTableHeadCellProps: { sx: { color: "#002677" }}, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder:'Product' },
      },
         {
        accessorKey: 'Page_no',
        header: 'Page No',
        minSize: 30,
        maxSize: 30,
        size: 30,
        Cell: ({ row }) => {
          let pageNo = row.original.Page_no;
          return <div style={{ paddingLeft: '18px' }}>
            {pageNo}
          </div>
        },
        muiTableHeadCellProps: { sx: { color: "#002677" }}, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder:'Page No' },

      },
      {
        accessorKey: 'contract_view',
        header: 'Contract View',
        minSize: 100,
        maxSize: 100,
        size: 100,
        muiTableHeadCellProps: { sx: { color: "#002677" }}, //custom props
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <div style={{ paddingLeft: '15px' }}>
            <img src={ContractIcon} alt={'contractImg'} style={{ cursor: 'pointer' }} onClick={() => handleContractViewImage(row.index, row.original.Agreement_Number, row.original.Page_no)} />
          </div>
        ),
      },
    ],
    [],
  );
  



  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableGrouping: false,
    // initialState: { showColumnFilters: true },
    groupedColumnMode: 'remove',
    enableSorting: false,
        editDisplayMode: 'table',
    // muiTableContainerProps: { sx: { } },
    enableDensityToggle: false,
    enableFullScreenToggle:false,
    // muiTableContainerProps: { sx: { maxHeight: '405px' } },
    
    muiTableHeadCellProps: { sx: { backgroundColor: "transparent"  ,fontSize: {
      xs: '10px',
      sm: '11px',
      md: '12px',
      lg: '13px',
      xl: '14px',
    },
    padding: '5px',
    } }, //custom props
    initialState:{columnVisibility:{Agreement_Number:false}},
    muiTableHeadRowProps:{sx:{backgroundColor: "dark blue"}},
    muiTopToolbarProps:{sx:{backgroundColor: "white"}},
    muiTableFooterRowProps:{sx:{backgroundColor: "white"}},
    muiBottomToolbarProps:{sx:{backgroundColor: "white"}},
     muiTablePaperProps:{
      sx:{
        boxShadow:'none',
        border:'none',
        backgroundColor:'white',
      }
    },
    muiTableProps:{
      sx:{
      borderCollapse:'collapse',
      }
    },
    // columnFilterDisplayMode: 'popover',
    muiTableBodyRowProps:{
      sx:(theme)=>( {
        borderSpacing:'0 10px',
        borderBottom:'5px solid white',
        fontSize:'1px',
      }),
    },
    muiPaginationProps: {
      color: 'primary',
      shape: 'circular',
      showRowsPerPage: false,
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
    // muiTableBodyRowProps:({row})=>({
    //   sx:{fontSize:'10px'}
    // }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Filtered Data
        </Button>

      </Box>
    ),

  });

 
//   const closeSnackBar=()=>{
//     setShowSnackbar(false);
//   }
 
  return (
    <div
//      ref={(ref) => {
//       if (ref) {
//           ref.addEventListener('click', closeSnackBar);
//       }
//   }}
  >
      {/* <SnackBar open={showSnackbar}/> */}
     
      {showTable &&
        <MaterialReactTable table={table} />
      }
      {/* {showLoader &&
        <Loader />
      }

      {showSimilarParagraphsPopup &&
        <ContarctsViewPopup close={closeSimilarParagraphsPopup} rowIndex={rowIndex} request_Id={requesId} doc_Number={docNumber} />
      } */}

      {showContractImage &&
        <ContractViewImage close={closeContractViewImage} rowIndex={rowIndex} agreement_Number={agreementNumber} page_Number={pageNumber} />

      }
    </div>
  )
}
export default SearchQueryResult