import React, { useState,useEffect,useRef } from 'react'
import SearchQueryResult from '../Components/SearchQueryResult';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import axios from "axios";
import { useParams } from 'react-router-dom';
// import AppInsight from '../AppInsight';
// import SnackBar from "../Component/SnackBar";
import useLoader from '../Hook/useLoader';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Sidebar from '../Components/Sidebar';
import Client from '../Components/Client';
import SnackBar from '../Components/SnackBar';


const SearchQueryPage = () => {
  const [data, setdata] = useState([]);
  const [contracts,setContracts] = useState("");
  const [mentions,setMentions] = useState("");
  const [clients,setClients] = useState("");
const [loader, showLoader, hideLoader] = useLoader();
const [showTable,setShowTable] = useState(false);
  const searchText = useRef(null);
  const [clientWithMentions,setClientsWithMentions]=useState(null);
  const [clientWithNoMentions,setClientsWithNoMentions]=useState(null);
  const [showClientPopup,setShowClientPopup]= useState(false);
  const [showClientWithMentionsPopup,setShowClientWithMentionsPopup] = useState(false);
  const alertRef=useRef();


  
  const params = useParams();
   useEffect(() => {
    try{
  searchText.current.value=params.text;
  handleSearch("Home");
    }
    catch(error){
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'error-in-adding-search-query-coming-from-home-into-search-box;searchpage =>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackBar(true);
   
    }
   }, [])
  
  const handleClientPopup = () => {
    
    setShowClientPopup(!showClientPopup);
  }
 
  const handleClientWithMentionsPopup=()=>{
    setShowClientWithMentionsPopup(!showClientWithMentionsPopup);
  }



  const handleSearch = async (calledFrom) => {
    // setShowSnackBar(false);
    if (searchText.current.value !== ""||calledFrom==="Home") {
      try {
        showLoader();
        setShowTable(false);
        const header = {
          headers: {
            'Content-Type': 'application/json'
          },
        };
        const json = (calledFrom==="Home")?JSON.stringify({ "query": params.text }):JSON.stringify({ "query": searchText.current.value });
        const response = await axios.post(process.env.REACT_APP_API_URI + "search", json, header)
        if(response !==null && response.status === 200)
        {
          setShowTable(true);
          hideLoader()
          // console.log("response is",response);
          setdata(response.data.Results);
        //   setContracts(response.data.stats.total_unique_contracts);
        //   setMentions(response.data.stats.total_results);
        //   setClients(response.data.stats.total_unique_clients);
          setClientsWithNoMentions(response.data.stats.Clients_No_Mentions_List);
          setClientsWithMentions(response.data.stats.Clients_Mentions_List);
          setMentions(response.data.stats.No_of_Clients_Mentions);
          setClients(response.data.stats.No_of_Clients_No_Mentions);
        }
      }
      catch (error) {
        setShowTable(false);
        hideLoader();
        alertRef.current.showSnackbar();
        // AppInsight.trackException({error});
        // AppInsight.trackEvent({name:'error-in-search-api=>frontend',properties:{errorMessage:error.message}});
      }
    }
    else { }
  }

  const handleKeyDown = (e) =>{
    if(e.key === 'Enter'){
      handleSearch();
    }
  }


  return (
    <div className="parent101">
    <div className="child101" >
    <div className="row1" style={{marginTop:'-1px'}}>
        <div className="column leftSpace">

          <div className="HeadingApp">
            <br></br>
            <input type="text" ref={searchText} readOnly onKeyDown={(e) => handleKeyDown(e)} style={{ width: '550px', height: '20px', borderRadius: '20px', marginTop: '3%', fontSize: '14px',padding:'17px'}} />
        
         </div>
        </div>
       {showTable &&
       <div >
        <div className="column left">
          {/* <div className='header contractImage'></div> */}
          <div className="marginCenter">
          <h6 style={{paddingTop:'12%'}}><b>{'104'}</b> </h6>
          </div>
          <div className="footer marginCenter">Total<br/>Contracts</div>
        </div>

        <div className="column middle" onClick={handleClientWithMentionsPopup} style={{cursor:'pointer'}}>
          {/* <div className="header mentionImage"></div> */}
          <div className="mainbody marginCenter">
            <h6 style={{paddingTop:'12%'}}><b>{mentions.toLocaleString()}</b> </h6>
          </div>
          <div className="footer marginCenter"> Clients  with <br/> Mentions</div>
        </div>

        <div className="column right" onClick={handleClientPopup} style={{cursor:'pointer'}}>
          {/* <div className="header noMentionImage"></div> */}
          <div className="mainbody marginCenter">
            <h6 style={{paddingTop:'12%'}}><b> {clients.toLocaleString()}</b></h6>
          </div>
          <div className="footer marginCenter"> Clients with <br/>No Mentions</div>
        </div>
        </div>
       }
       </div>

{
        showClientPopup &&
        <Client data={clientWithNoMentions} close={handleClientPopup} title="Client with No Mentions"/>
      }
      {
        showClientWithMentionsPopup &&
        <Client data={clientWithMentions} close={handleClientWithMentionsPopup} title="Client with Mentions"/>
      }  
    </div>
    <div className="main">
      <div className="child">    <Sidebar/>
  </div>
      <div className="child3 content">
        <div className="width101" >
  
          {loader}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SearchQueryResult called="main" data = {data}  tableValue={showTable}/>

  
  
  
  </LocalizationProvider>
  </div>
  
      </div>  
    </div>
    <SnackBar ref={alertRef}/>     

  </div>
  )
}

export default SearchQueryPage;