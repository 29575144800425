import React,{ useEffect,useState,useRef } from 'react';
import ClientSummaryTable from '../Components/ClientSummaryTable';
import Sidebar from '../Components/Sidebar';
import { FaArrowRightLong } from "react-icons/fa6";
import { useParams,useLocation } from 'react-router-dom';
import axios from 'axios';
import useLoader from "../Hook/useLoader";
import ExpandText from '../Components/ExpandText';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import SnackBar from '../Components/SnackBar';
import { useSelector } from 'react-redux';
import { TokenGetter, GetUserInfo,getCookie,setCookie } from "../Endpoints/LoginActions";






const Card=({contract,effectiveDate,offshoreFlag})=>{
  return(
    <div className='cardContainer'>
      <div className='blueSection'>
       <label>{contract}
       </label> 
      </div>
      <div  className='whiteSection'>
        <label className='bold'>Effective Date:<label className='paddingLeft normal'>{effectiveDate}</label></label>
        <label className='bold'>Offshore Flag:<label className='paddingLeft normal'>{offshoreFlag}</label></label>
      </div>
    </div>
  )
}

const ClientSummary=(props)=> {
    const [showTable, setShowTable] = useState(false);
    const[cards,setCards]=useState([])
    const query=new URLSearchParams(useLocation().search);
    const params=useParams();
    const request_id=query.get('id');
    const [loader, showLoader, hideLoader] = useLoader();
    const { instance, inProgress, accounts } = useMsal();
    const navigate=useNavigate();
    const userDetail = accounts.length > 0 ? accounts[0] : null;
    const isAuthenticated = useIsAuthenticated();
    const[struct,setStructure]=useState([]);
    const alertRef=useRef();

    const userInfoReduxPersist = useSelector((state) => {  //get data from the main store
      return state.UserInfoSlice;
  })
  const userInfo =JSON.parse(sessionStorage.getItem('userInfoObj'));

  const userInfoSessionStorage= JSON.parse(sessionStorage.getItem("userInfo"));

  // useEffect(()=>{
  //     console.log("redux persist userinfo is,....",userInfoReduxPersist);
  // },[])
  
  

       
  const handleIconClick=(id,rowIndex,type)=>{
    setStructure(prevData=>
       prevData.map((itemmm)=>{
      if(itemmm.agreement_id===id){
        let updatedData=itemmm.para_data.map((item,index)=>
        index===rowIndex?{...item,review_status:type}:item);
        const allAccepted=updatedData.every((item)=>item.review_status==='Accepted')
        const allDenied=updatedData.every((item)=>item.review_status==='Denied')
      return {...itemmm,para_data:updatedData,
        selectAll:{Accepted:allAccepted,Denied:allDenied},
      };
      }
    return itemmm;
    },
  ))
  };


  const handleCheckboxClick=(id,type)=>{
    const isAccepted=type==='Accepted';
    setStructure(prevData=>
      prevData.map((item)=>
      item.agreement_id===id
    ?{
      ...item,para_data:item.para_data.map((item)=>({
        ...item,review_status:isAccepted?'Accepted':'Denied',
      })),
      selectAll:{Accepted:isAccepted,Denied:!isAccepted},
    }
    :item
   )
);    
};

const feedbackHandleChange=(id,rowIndex,e)=>{
  setStructure((prevData)=>
    prevData.map((itemm)=>
   itemm.agreement_id===id?
   {...itemm,para_data:itemm.para_data.map((item,index)=>
  index===rowIndex?{...item,feedback:e}:item
  ),
 }
 :itemm
 )); 
};

const validateAllTableData=()=>{
  const inCompleteTableData=struct.map((itemm)=>{
    const isIncomplete=itemm.para_data.some((row)=>!row.review_status);
    return {agreement_id:itemm.agreement_id,isIncomplete};
  });
  const incomplete=inCompleteTableData.find((item)=>item.isIncomplete);
  if(incomplete){
    alertRef.current.showSnackbar("Please review all the results to submit the response.","error");
    return false;
  }
  return true;
}


const logTableData=async()=>{    
try{

  if(!validateAllTableData()){
    return;
  }
 const transFormData= {
  "request_id": request_id,
  "contract_data": struct.map(({contract_name,para_data,selectAll,...rest})=>({
    ...rest,
    para_data:para_data.map(item=>({
      feedback:item.feedback,
      paragraph:item.paragraph,
      status:item.review_status,
    })),
    
    //reviewed_by:(userInfo===null)?userDetail.name:userInfoReduxPersist.userInfo.name.split(" ")[1]+", "+userInfoReduxPersist.userInfo.name.split(" ")[0]
    reviewed_by:(userInfoSessionStorage===null)?userDetail?.name:userInfoSessionStorage.name.split(" ")[1]+", "+userInfoSessionStorage.name.split(" ")[0]

  }))}
  // showLoader();
  const response = await axios.post(
    process.env.REACT_APP_API_URI+"update_processed_contracts",transFormData
  );
  if(response.status===200){
    // hideLoader();
    alertRef.current.showSnackbar("Review Completed","success");
    navigate("/Archive");
  }
 
}catch(error){
  alertRef.current.showSnackbar();


}

}    
  

    const postApiCall=async()=>{
   try {
    const struct={
      "request_id": request_id,
      "Payer Name": ""+params.payer_name+""
  }
    showLoader();
    const response = await axios.post(
      process.env.REACT_APP_API_URI+"client_summary",struct
    );
    if(response.status===200){
      setShowTable(true);
      hideLoader();
   const initialRequest=response.data.Data.map((item)=>({
          ...item,para_data:item.para_data.map((item)=>({
            ...item,
          })),
          selectAll:{Accepted:false,Denied:false},
        }))
        setStructure(initialRequest);
    }
    setCards(response.data.headerData);
  } catch (error) {
    hideLoader()
    alertRef.current.showSnackbar();
 

  }
  }

           
    useEffect(()=>{
        postApiCall()
      },[]);

     
     
  return (
    
    
    <div>
    <div className="parentClientSummary">
  <div className="child7" >
    {showTable&&
    <div style={{display:'flex'}}>
      <div style={{color:'darkblue',display:'inline',fontWeight:'bold',position:'absolute',fontSize: "20px"}}>{`Payer Name: ${params.payer_name}`}</div>
      <div  style={{color:'darkblue',paddingLeft:'78%',display:'inline',fontWeight:'bold',position:'absolute',fontSize: "20px"}}>{`Total Contracts: ${cards.length}`}</div>
    </div>
}
    <div style={{height:'45%',marginTop:'3%'}}>
    <div className='appContainer fontSize '>
      {cards.map((card,index)=>(
        <React.Fragment key={index}>
          <Card contract={card.Contract_Name} effectiveDate={card.Effective_Date} offshoreFlag={card.Offshore_Prediction} />
          {index<cards.length-1&&(
            <div className='arrowContainer'>
              <span className='arrow'><FaArrowRightLong /></span>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
    </div>
    
  </div>
  <div className="main">
    <div className="child"><Sidebar/>
</div>
    <div className="child5 content">
      <div className="width101" >
        <br></br>
        {loader}
        {struct.map((table,index)=>(
          
          <div key={index} >
            <ClientSummaryTable
                                // wholeData={wholeData}
                                data={table}
                                tableValue={showTable}
                                contract_name={table.contract_name}
                                agreement_id={table.agreement_id}
                                handleIconClick={handleIconClick}
                                handleCheckboxClick={handleCheckboxClick}
                                feedbackHandleChange={feedbackHandleChange}
                            />
                            <br></br>                            
          </div>
        ))}
      {showTable&&  <button className="btn btn-primary"
       onClick={logTableData} 
       style={{ backgroundColor: '#002377', marginTop: '0px', width: '14%', borderRadius: '0px', marginBottom: '10px' ,marginLeft:'45%'}} 
          >Submit</button>}
</div>
<SnackBar ref={alertRef}/>     

    </div>  
  </div>
</div>
   </div>
  )
}

export default ClientSummary





