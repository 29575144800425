import React from 'react'

const Loader = ({heightOfLoader,marginTopLoader}) => {
    return (
        <div className="loader_background" style={{height:heightOfLoader}}>
            <div className="spinner-border text-primary" style={{ width: '5rem', height: '5rem', role: 'status',marginTop:'25%', marginLeft: '46%', marginBottom: '5%' }}>
                <span className="visually-hidden">Processing...</span>

            </div>
        </div>

    )
}
export default Loader;