import {createSlice} from "@reduxjs/toolkit";

const UserInfoSlice= createSlice({
    name: "UserInfoSlice",
    initialState:{
        userInfo:"",

    },
    reducers: {
        saveUserInfo(state,action){
            // console.log("reducer called")

            return{
                ...state,
                userInfo:action.payload,

            }
        },

       
    }
});

export default UserInfoSlice.reducer;
export const { saveUserInfo } = UserInfoSlice.actions;